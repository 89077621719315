import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"

const Team1 = () => {
  useTranslation();

  return (
    <>
      <section className="team-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb-60">
                <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                  <Trans i18nKey="team.title" />
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="team-item-two">
                <div className="team-thumb-two">
                  <img src="assets/img/team/antonio.png" alt="" />
                </div>
                <div className="team-content-two">
                  <div className="team-info">
                    <h4 className="title">Antonio Affonseca</h4>
                    <span>Founder & CEO</span>
                  </div>
                  <div className="team-social">
                    <ul className="list-wrap">
                      <li>
                        <Link to="https://www.linkedin.com/in/antonio-affonseca/" target="_blank">
                          <i className="fab fa-linkedin-in" style={{fontSize:'22px'}} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="team-item-two">
                <div className="team-thumb-two">
                  <img src="assets/img/team/barbara.png" alt="" />
                </div>
                <div className="team-content-two">
                  <div className="team-info">
                    <h4 className="title">Barbara Akemi</h4>
                    <span>Founder & CRO </span>
                  </div>
                  <div className="team-social">
                    <ul className="list-wrap">
                      <li>
                        <Link to="https://www.linkedin.com/in/baakemi/" target="_blank">
                          <i className="fab fa-linkedin-in" style={{fontSize:'22px'}} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="team-item-two">
                <div className="team-thumb-two">
                  <img src="assets/img/team/cadu.png" alt="" />
                </div>
                <div className="team-content-two">
                  <div className="team-info">
                    <h4 className="title">Cadu Trevisan</h4>
                    <span>Co-Founder & COO</span>
                  </div>
                  <div className="team-social">
                    <ul className="list-wrap">
                      <li>
                        <Link to="https://www.linkedin.com/in/cadutrevisan/" target="_blank">
                          <i className="fab fa-linkedin-in" style={{fontSize:'22px'}} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="counter-area pt-10 pb-105" style={{ position: "relative" }}>
        <div className="contact-form-wrap" style={{ marginBottom: '60px' }}>
          <h3 className="title">
            <Trans i18nKey="team.partnersTitle" />
          </h3>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/brand_img04.png" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/brand_img03.png" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/brand_img02.png" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/brand_img01.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="counter-area pt-10 pb-105" style={{ position: "relative" }}>
        <div className="contact-form-wrap" style={{ marginBottom: '60px' }}>
          <h3 className="title">
            <Trans i18nKey="team.brandsTitle" />
          </h3>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/company_banco_pan.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/company_bradesco.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/company_digio.png" alt="" />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/company_lalamove.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/company_natura.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/company_neon.png" alt="" />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/company_nubank.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="counter-item with-logo">
                <img src="/assets/img/brand/company_wine.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Team1;
