import { Trans, useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect'

const Banner1 = () => {
    const { t } = useTranslation();

    const handleScroll = (event, path) => {
        event.preventDefault();

        const targetId = event.currentTarget.getAttribute('href');
        const targetElement = document.querySelector(targetId);

        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop - 130,
                behavior: 'smooth'
            });
        }
    };

    const wordList = [
        'Rentability',
        'Growth',
        'Acquisition',
        'Retention',
        'Marketing',
        'Optimization',
        'Success',
    ].map(word => t(word));
    var randomWord = wordList[Math.floor(Math.random() * wordList.length)];

    return (
        <section className="banner-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="banner-content ta-animated-headline">
                            <div className="title ah-headline" data-wow-delay=".5s">
                                <img className="logo-img" height="250" src="assets/img/logo/logo-img.png" alt="logo" />
                                <h1 className="logo-title">530 Broadway</h1>
                                <h1 className="logo-description">MEDIA</h1>
                                <div className="logo-typewriter">
                                    <span><Trans i18nKey="yourSolutionPrefix" /> </span>
                                    <Typewriter tag="span"
                                        options={{
                                            wrapperClassName: "ah-words-wrapper",
                                            strings: wordList,
                                            autoStart: true,
                                            loop: true,
                                            delay:75,
                                            typeSpeed: 10
                                        }}
                                    />
                                </div>
                            </div>
                            <h2 className="title d-none" data-wow-delay=".2s">
                                {t('yourSolutionPrefix')} <span>{randomWord}</span>
                            </h2>
                            <p className="" data-wow-delay=".4s">
                                <Trans i18nKey="bannerDescription" />
                            </p>
                            <div className="banner-btn">
                                <a href="#contact" className="gradient-btn" data-wow-delay=".6s" onClick={(e) => handleScroll(e, "/services")}>
                                    <Trans i18nKey="getInTouch" />
                                </a>
                                <a href="#services" className="gradient-btn gradient-btn-two" data-wow-delay=".6s" onClick={(e) => handleScroll(e, "/contact")}>
                                    <Trans i18nKey="getToKnowUs" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner1;