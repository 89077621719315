import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";

const Footer1 = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="copyright-text">
                  <p>
                    Copyright © {new Date().getFullYear()} 530Broadway <Trans i18nKey="allRightsReserved"/>
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-menu">
                  <div className="footer-social">
                    <ul className="list-wrap">
                      <li>
                        <Link to="https://www.linkedin.com/company/530broadway-media/" target="_blank">
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer1;
