import { Trans, useTranslation } from 'react-i18next';
import React, { useState, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const infoWindowStyles = {
  fontSize: '14px',
  color: 'black',
};

const headerStyles = {
  fontSize: '16px',
  marginBottom: '5px',
  fontWeight: 'bold',
};

const mapStyles = [
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [{ saturation: 36 }, { color: '#000000' }, { lightness: 50 }],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'on' }, { color: '#000000' }, { lightness: 16 }],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [{ color: '#000000' }, { lightness: 20 }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#000000' }, { lightness: 17 }, { weight: 1.2 }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#000000' }, { lightness: 30 }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#000000' }, { lightness: 21 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#000000' }, { lightness: 17 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#000000' }, { lightness: 18 }],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [{ color: '#000000' }, { lightness: 16 }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#000000' }, { lightness: 19 }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#000000' }, { lightness: 17 }],
  },
];

const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true, // this will disable all controls
  zoomControl: false, // optional, in case you want to enable zoom control
};

const locations = [
  {
    id: 'madrid',
    name: 'Madrid, Spain',
    position: { lat: 40.4168, lng: -3.7038 },
    address: 'Calle Gil-Vernet 54/55, Les Tapies 1, Hospitalet de l\'Infant, Tarragona 43890'
  },
  {
    id: 'saoPaulo',
    name: 'São Paulo, Brazil',
    position: { lat: -23.5505, lng: -46.6333 },
    address: 'Av. Brig. Faria Lima, 3729'
  },
  {
    id: 'newYork',
    name: 'New York, US',
    position: { lat: 40.7128, lng: -74.0060 },
    address: '447 Broadway, 2nd Floor, NY 10013'
  },
];

const averageLat = locations.reduce((sum, loc) => sum + loc.position.lat, 0) / locations.length;
const averageLng = locations.reduce((sum, loc) => sum + loc.position.lng, 0) / locations.length;

const center = {
  lat: averageLat,
  lng: averageLng,
};

const Contact1 = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [markerIcon, setMarkerIcon] = useState(null);
  const { t } = useTranslation();

  const onLoad = useCallback(() => {
    setMarkerIcon({
      path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6 12 6s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z',
      fillColor: '#808080',
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 2,
      anchor: new window.google.maps.Point(12, 24),
    });
  }, []);


  return (
    <>
      <div>
      <section id="contact" className="contact-area pb-140">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="contact-form-wrap">
                        <h2 className="title">
                            <Trans i18nKey="contact.title" />
                        </h2>
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <div className="contact-form">
                                    <form action="https://formspree.io/f/mnqkwjwd" method="POST">
                                      <input type="hidden" name="_gotcha" style={{display:"none !important"}} />
                                        <div className="form-grp">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder={t("contact.placeholders.name")}
                                                required
                                            />
                                        </div>
                                        <div className="form-grp">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder={t("contact.placeholders.email")}
                                                required
                                            />
                                        </div>
                                        <div className="form-grp">
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                placeholder={t("contact.placeholders.phone")}
                                                required
                                            />
                                        </div>
                                        <div className="form-grp">
                                            <textarea
                                                id="message"
                                                name="message"
                                                placeholder={t("contact.placeholders.description")}
                                            />
                                        </div>
                                        <button className="btn" type="submit">
                                            <Trans i18nKey="contact.submit"/>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

      </div>
      <LoadScript googleMapsApiKey="AIzaSyBq-9a78Ik6QSHm7Brz7d3zpHYdMV0lIMs">
      {/* AIzaSyAGQbRg014lqxS_BFojn4ndvLPlsOfqb0E */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={2}
          options={mapOptions}
          onLoad={onLoad}
        >
          {markerIcon && locations.map(location => (
            <Marker
              key={location.id}
              position={location.position}
              onClick={() => setSelectedLocation(location)}
              icon={markerIcon}
              label={{
                text: location.name,
                color: '#FFFFFF',
                fontSize: '12px',
                fontWeight: 'bold',
                className: 'marker-label',
              }}
              labelStyle={{
                whiteSpace: 'nowrap',
                width: 'max-content',
                textAlign: 'center',
                transform: 'translateX(-0%) translateY(100%)',
              }}
              labelAnchor={new window.google.maps.Point(0, 45)}
            />
          ))}
          {selectedLocation && (
            <InfoWindow
              position={selectedLocation.position}
              onCloseClick={() => setSelectedLocation(null)}
            >
              <div style={infoWindowStyles}>
                <div style={headerStyles}>{selectedLocation.name}</div>
                <div>{selectedLocation.address.split(', ').map((line, index) => (<React.Fragment key={index}>{line}<br/></React.Fragment>))}</div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
};
export default Contact1;
