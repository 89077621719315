import { useEffect, useState } from "react"
import Header1 from "../header/Header1"
import DataBg from "./DataBg"
import Footer1 from "../footer/Footer1"
import GoTop from "./GoTop"
import Breadcrumb from "./Breadcrumb"
import { useTranslation } from 'react-i18next';

const Layout = ({ headerStyle, footerStyle, headTitle, breadcrumbTitle, children, mainCls }) => {
    useTranslation();

    const [scroll, setScroll] = useState(0)

    // Moblile Menu
    const [isMobileMenu, setMobileMenu] = useState(false)
    const handleMobileMenu = () => {
        setMobileMenu(!isMobileMenu)
        !isMobileMenu ? document.body.classList.add("mobile-menu-visible") : document.body.classList.remove("mobile-menu-visible")
    }
    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 100
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        })

        const WOW = require('wowjs')
        window.wow = new WOW.WOW({
            live: false
        })
        window.wow.init()
    }, [scroll])
    return (
        <>

            {!headerStyle && <Header1 scroll={scroll} isMobileMenu={isMobileMenu} handleMobileMenu={handleMobileMenu} />}
            {headerStyle === 1 ? <Header1 scroll={scroll} isMobileMenu={isMobileMenu} handleMobileMenu={handleMobileMenu} /> : null}


            <DataBg />

            <main id="home" className={mainCls ? mainCls : "main-content"}>
                <div className="noise-bg" data-background="/assets/img/bg/noise_bg.png" />
                <div className="main-shape" data-background="/assets/img/images/main_shape.png" />
                {breadcrumbTitle && <Breadcrumb breadcrumbTitle={breadcrumbTitle} />}

                {children}
            </main>
            {!footerStyle && <Footer1 />}
            {footerStyle === 1 ? <Footer1 /> : null}

            <GoTop />
        </>
    )
}
export default Layout