import { Trans, useTranslation } from 'react-i18next';

const Roadmap = () => {
  useTranslation();

  return (
    <section id="services" className="roadmap-area pb-130">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-70">
              <h2
                className="title title-animation wow fadeInUp"
                data-wow-delay=".2s"
              >
                <Trans i18nKey="roadmap.title" />
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-9">
            <div className="roadmap-timeline-wrap">
              <div className="roadmap-line" data-background="assets/img/brand/Line.svg"/>
              <div className="roadmap-line roadmap-line2" data-background="assets/img/brand/Line.svg"/>
              <ul className="list-wrap">
                <li>
                  <div className="roadmap-item">
                    <div
                      className="roadmap-img wow fadeInLeft"
                      data-wow-delay=".2s"
                    >
                      <img src="/assets/img/services/performance.png" alt="" />
                    </div>
                    <div
                      className="roadmap-content wow fadeInRight"
                      data-wow-delay=".2s"
                    >
                      <h4 className="title"><Trans i18nKey="roadmap.performance.title" /></h4>
                      <p>
                        <Trans i18nKey="roadmap.performance.description" />
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="roadmap-item st">
                    <div
                      className="roadmap-img wow fadeInLeft"
                      data-wow-delay=".2s"
                    >
                      <img src="/assets/img/services/preloading.png" alt="" />
                    </div>
                    <div
                      className="roadmap-content wow fadeInRight"
                      data-wow-delay=".2s"
                    >
                      <h4 className="title"><Trans i18nKey="roadmap.preload.title" /></h4>
                      <p>
                        <Trans i18nKey="roadmap.preload.description" />
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="roadmap-item">
                    <div
                      className="roadmap-img wow fadeInRight"
                      data-wow-delay=".2s"
                    >
                      <img src="/assets/img/services/tv.png" alt="" />
                    </div>
                    <div
                      className="roadmap-content wow fadeInLeft"
                      data-wow-delay=".2s"
                    >
                      <h4 className="title"><Trans i18nKey="roadmap.connectTV.title" /></h4>
                      <p>
                        <Trans i18nKey="roadmap.connectTV.description" />
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="roadmap-item st">
                    <div
                      className="roadmap-img wow fadeInLeft"
                      data-wow-delay=".2s"
                    >
                      <img src="/assets/img/services/branding.png" alt="" />
                    </div>
                    <div
                      className="roadmap-content wow fadeInRight"
                      data-wow-delay=".2s"
                    >
                      <h4 className="title"><Trans i18nKey="roadmap.branding.title" /></h4>
                      <p>
                        <Trans i18nKey="roadmap.branding.description" />
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="roadmap-item">
                    <div
                      className="roadmap-img wow fadeInRight"
                      data-wow-delay=".2s"
                    >
                      <img src="/assets/img/services/Influence.png" alt="" />
                    </div>
                    <div
                      className="roadmap-content wow fadeInLeft"
                      data-wow-delay=".2s"
                    >
                      <h4 className="title"><Trans i18nKey="roadmap.influenceMarketing.title" /></h4>
                      <p>
                        <Trans i18nKey="roadmap.influenceMarketing.description" />
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Roadmap;
