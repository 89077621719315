import LanguageDropdown from "./LanguageDropdown";
import { Link } from "react-router-dom";
import MenuMobile from "./MenuMobile";
import { Trans, useTranslation } from 'react-i18next';

const Header1 = ({ scroll, isMobileMenu, handleMobileMenu }) => {

  const handleScroll = (event, path) => {
      event.preventDefault();

      const targetId = event.currentTarget.getAttribute('href');
      const targetElement = document.querySelector(targetId);

      if (targetElement) {
          window.scrollTo({
              top: targetElement.offsetTop - 130,
              behavior: 'smooth'
          });
      }
  };

  useTranslation();

  return (
    <header>
      <div
        id="sticky-header"
        className={`menu-area transparent-header ${
          scroll ? "sticky-menu" : ""
        }`}
      >
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler" onClick={handleMobileMenu}>
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo" style={{ width: "20%" }}>
                    <Link to="/">
                      <img src="/assets/img/logo/logo.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li>
                          <a href="#home" onClick={(e) => handleScroll(e, "/")}><Trans i18nKey="menu.home" /></a>
                      </li>
                      <li>
                          <a href="#services" onClick={(e) => handleScroll(e, "/services")}><Trans i18nKey="menu.services" /></a>
                      </li>
                      <li>
                          <a href="#contact" onClick={(e) => handleScroll(e, "/contact")}><Trans i18nKey="menu.contact" /></a>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block" style={{ width: "20%" }}>
                    <ul className="list-wrap">
                      <li className="header-lang">
                        <LanguageDropdown />
                      </li>
                      <li className="header-btn">
                        &nbsp;
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn" onClick={handleMobileMenu}>
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo" onClick={handleMobileMenu}>
                    <Link to="/">
                      <img src="/assets/img/logo/logo.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="menu-outer">
                    <MenuMobile handleCloseMenu={handleMobileMenu} />
                  </div>
                  <div className="social-links">
                    <ul className="clearfix list-wrap">
                      <li>
                        <Link to="https://www.linkedin.com/company/530broadway-media/" target="_blank">
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" onClick={handleMobileMenu} />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header1;
