import { Trans, useTranslation } from 'react-i18next';

function MenuMobile({ handleCloseMenu }) {
  const handleScroll = (event, path) => {
    event.preventDefault();

    const targetId = event.currentTarget.getAttribute('href');
    const targetElement = document.querySelector(targetId);

    if (targetElement) {
        window.scrollTo({
            top: targetElement.offsetTop - 130,
            behavior: 'smooth'
        });
    }
  };

  useTranslation();

  return (
    <ul className="navigation">
      <li className={`menu-item-has-children tg-mega-menu-has-children`}>
        <a href="#home" onClick={(e) => handleScroll(e, "/")}><Trans i18nKey="menu.home" /></a>
      </li>
      <li className={`menu-item-has-children tg-mega-menu-has-children`} onClick={handleCloseMenu}>
        <a href="#services" onClick={(e) => handleScroll(e, "/services")}><Trans i18nKey="menu.services" /></a>
      </li>
      <li className={`menu-item-has-children tg-mega-menu-has-children`} onClick={handleCloseMenu}>
        <a href="#contact" onClick={(e) => handleScroll(e, "/contact")}><Trans i18nKey="menu.contact" /></a>
      </li>
    </ul>
  );
}

export default MenuMobile;
