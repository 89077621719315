import { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LanguageDropdown() {
    const { i18n } = useTranslation();
    const defaultLanguage = 'en';
    const [currentLanguage, setCurrentLanguage] = useState(null);

    const languages = {
        en: {
            name: "EN",
            flag: "/assets/img/icon/united-states02.png"
        },
        pt: {
            name: "BR",
            flag: "/assets/img/icon/brazil02.png"
        },
        es: {
            name: "ES",
            flag: "/assets/img/icon/spain02.png"
        }
    };

    const changeLanguage = (langCode) => {
        i18n.changeLanguage(langCode);
        setCurrentLanguage(langCode);
    };

    const normalizeLanguageCode = (langCode) => {
        if (langCode.includes('pt')) return 'pt';
        if (langCode.includes('es')) return 'es';
    };

    useEffect(() => {
        setCurrentLanguage(normalizeLanguageCode(i18n.language) || defaultLanguage);

        const handleLanguageChanged = (lng) => setCurrentLanguage(lng);
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [i18n]);

    if (!currentLanguage) {
        return null;
    }

    return (
        <>
            <Menu as="div" className="dropdown">
                <Menu.Button className="dropdown-toggle show" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={languages[currentLanguage]?.flag || languages[defaultLanguage].flag} alt={currentLanguage} /> {languages[currentLanguage]?.name || languages[defaultLanguage].name}
                </Menu.Button>
                <Menu.Items as="div" className="dropdown-menu show" aria-labelledby="dropdownMenuButton1">
                    {Object.keys(languages).map(langCode => (
                        langCode !== currentLanguage && (
                            <Link key={langCode} className="dropdown-item" to="/" onClick={() => changeLanguage(langCode)}>
                                <img src={languages[langCode].flag} alt={langCode} /> {languages[langCode].name}
                            </Link>
                        )
                    ))}
                </Menu.Items>
            </Menu>
        </>
    );
}
